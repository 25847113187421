export const light = {
  bg: {
    primary: '#fff',
    second: '#23797B',
    third: '#154D4E',
    fourth: '#CCDD00',
    fifth: '#F9F8F7',
    sixth: '#EFEFEF',
    seventh: '#111111',
    eigth: '#212121',
    dark: '#333333',
  },
  font: {
    primary: '#757474',
    second: '#333333',
    third: '#23797B',
    fourth: '#CCDD00',
    fifth: '#9E9E9E',
    error: '#FF0000',
    white: '#fff',
    black: '#000',
  },
  border: {
    borderBtn: '#23797B',
    borderBtnWhite: '#fff',
  },
};
