// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bank-as-a-service-index-tsx": () => import("./../../../src/pages/bank-as-a-service/index.tsx" /* webpackChunkName: "component---src-pages-bank-as-a-service-index-tsx" */),
  "component---src-pages-consignado-as-a-service-index-tsx": () => import("./../../../src/pages/consignado-as-a-service/index.tsx" /* webpackChunkName: "component---src-pages-consignado-as-a-service-index-tsx" */),
  "component---src-pages-contato-index-tsx": () => import("./../../../src/pages/contato/index.tsx" /* webpackChunkName: "component---src-pages-contato-index-tsx" */),
  "component---src-pages-corporation-index-tsx": () => import("./../../../src/pages/corporation/index.tsx" /* webpackChunkName: "component---src-pages-corporation-index-tsx" */),
  "component---src-pages-credito-consignado-privado-index-tsx": () => import("./../../../src/pages/credito-consignado-privado/index.tsx" /* webpackChunkName: "component---src-pages-credito-consignado-privado-index-tsx" */),
  "component---src-pages-credito-pj-as-a-service-index-tsx": () => import("./../../../src/pages/credito-pj-as-a-service/index.tsx" /* webpackChunkName: "component---src-pages-credito-pj-as-a-service-index-tsx" */),
  "component---src-pages-formulario-atendimento-index-tsx": () => import("./../../../src/pages/formulario-atendimento/index.tsx" /* webpackChunkName: "component---src-pages-formulario-atendimento-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-plataforma-index-tsx": () => import("./../../../src/pages/plataforma/index.tsx" /* webpackChunkName: "component---src-pages-plataforma-index-tsx" */),
  "component---src-pages-quem-somos-index-tsx": () => import("./../../../src/pages/quem-somos/index.tsx" /* webpackChunkName: "component---src-pages-quem-somos-index-tsx" */)
}

